import endpoints from '@/js/endpoints';
import { getModuleProps } from '@/js/utils';
import GeneralTemplate from '@/js/vuexTemplates/general';

const connectorState = {};

const connectorGetters = {
  nameFromId: (state) => (id) => {
    if (id && id in state.items) {
      return state.items[id].name;
    }
    return 'Not found';
  },
  typeFromId: (state) => (id) => {
    if (id && id in state.items) {
      return state.items[id].type;
    }
    return null;
  },
  audioConfigFromId: (state) => (id) => state.items[id]?.audioConfig || null,
};
const mutations = {};

const actions = {};

const templates = [
  new GeneralTemplate(endpoints.dataSource, 'connector'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: connectorState, getters: connectorGetters, mutations, actions,
  }, templates),
};
