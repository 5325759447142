import store from '@/store/index';

export function getDisplayFromId(key, value) {
  let display;
  switch (key) {
    case 'connectorId':
      display = store.state.connector.details ? store.state.connector.details.name : null;
      break;
    case 'analyzerId':
      display = store.state.analyzer.details ? store.state.analyzer.details.name : null;
      break;
    default:
      display = value;
  }

  return display;
}

export function getBreadcrumb(match, params) {
  // if the path ends with / or is empty, it means it is some route that is accessed
  // from base route, so it already has a match

  if (match.path.charAt(match.path.length - 1) === '/') {
    return null;
  }

  if (!match.path.length) {
    return null;
  }

  // get parameter key and value of match
  // route params with special formatting need to be handled separately
  const paramKey = match.path.split('/').pop().trim().substring(1);
  const paramValue = params[paramKey];

  // create link and display name
  const link = { name: match.name, params: { paramKey: paramValue } };
  const display = getDisplayFromId(paramKey, paramValue);

  return { display, link };
}
