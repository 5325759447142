import endpoints from '@/js/endpoints';
import { agentAnonymizationMode } from '@/js/featureFlags';

export const connectorTypes = {
  ZENDESK: { value: 'zendeskdatasource', text: 'Zendesk' },
  PUZZEL: { value: 'puzzeldatasource', text: 'Puzzel' },
  TRIO: { value: 'triodatasource', text: 'Trio' },
  // THREECX: { value: 'threecxdatasource', text: '3CX' },
  FILE_UPLOAD: { value: 'fileuploaddatasource', text: 'File Upload' },
};
export const connectorGroupNames = {
  zendeskdatasource: 'department',
  puzzeldatasource: 'queue',
  triodatasource: 'service',
  threecxdatasource: 'department',
  fileuploaddatasource: 'department',
};
export const connectorAudioConfigs = {
  STEREO: { value: 'stereo', text: 'Stereo' },
  MONO: { value: 'mono', text: 'Mono' },
};
export const connectorTypeToEndpoint = {
  zendeskdatasource: endpoints.dataSourceZendesk,
  puzzeldatasource: endpoints.dataSourcePuzzel,
  triodatasource: endpoints.dataSourceTrio,
  threecxdatasource: endpoints.dataSourceThreeCX,
  fileuploaddatasource: endpoints.dataSourceFileUpload,
};

export const featureSupport = Object.freeze({
  pushTags: [connectorTypes.ZENDESK],
  pushTranscripts: [connectorTypes.ZENDESK],
  pushSummaries: [connectorTypes.ZENDESK],
});

export const CallStatusTypes = Object.freeze([
  { value: 'fetching_source', text: '1. Fetching from connector' },
  { value: 'uploading', text: '2. Uploading for transcription' },
  { value: 'transcribing', text: '3. Transcribing' },
  { value: 'fetching_azure', text: '4. Fetching transcription' },
  { value: 'transcribed', text: '5. Transcribed' },
  { value: 'handling', text: '6. Handling transcription' },
  { value: 'cleaning_up', text: '7. Cleaning up' },
  { value: 'done', text: 'Done' },
  { value: 'failed', text: 'Failed' },
  { value: 'rehandling', text: 'Rehandling' },
]);

export const statisticsConfigSource = Object.freeze({
  AGENT_INSIGHTS: 'agentInsights',
  CONVERSATION_INSIGHTS: 'conversationInsights',
  ANALYTICS: 'analytics',
});

export const predictionStrategyTypes = Object.freeze([
  { value: 'full', text: 'Full' },
  { value: 'first_60_sec', text: 'First 60 seconds' },
  { value: 'visitor_only', text: 'Visitor only' },
]);

export const SpeakerFilterTypes = Object.freeze([
  { value: 'both', text: 'Both' },
  { value: 'visitor', text: 'Visitor' },
  { value: 'agent', text: 'Agent' },
]);

// List of supported languages in the backend:
// https://docs.microsoft.com/en-us/azure/cognitive-services/speech-service/language-support
export const languageOptions = Object.freeze([
  { value: 'ar-DZ', text: 'Arabic (Algeria)' },
  { value: 'ar-BH', text: 'Arabic (Bahrain), modern standard' },
  { value: 'ar-EG', text: 'Arabic (Egypt)' },
  { value: 'ar-IQ', text: 'Arabic (Iraq)' },
  { value: 'ar-IL', text: 'Arabic (Israel)' },
  { value: 'ar-JO', text: 'Arabic (Jordan)' },
  { value: 'ar-KW', text: 'Arabic (Kuwait)' },
  { value: 'ar-LB', text: 'Arabic (Lebanon)' },
  { value: 'ar-LY', text: 'Arabic (Libya)' },
  { value: 'ar-MA', text: 'Arabic (Morocco)' },
  { value: 'ar-OM', text: 'Arabic (Oman)' },
  { value: 'ar-QA', text: 'Arabic (Qatar)' },
  { value: 'ar-SA', text: 'Arabic (Saudi Arabia)' },
  { value: 'ar-PS', text: 'Arabic (Palestinian Authority)' },
  { value: 'ar-SY', text: 'Arabic (Syria)' },
  { value: 'ar-TN', text: 'Arabic (Tunisia)' },
  { value: 'ar-AE', text: 'Arabic (United Arab Emirates)' },
  { value: 'ar-YE', text: 'Arabic (Yemen)' },
  { value: 'bg-BG', text: 'Bulgarian (Bulgaria)' },
  { value: 'ca-ES', text: 'Catalan (Spain)' },
  { value: 'zh-HK', text: 'Chinese (Cantonese, Traditional)' },
  { value: 'zh-CN', text: 'Chinese (Mandarin, Simplified)' },
  { value: 'zh-TW', text: 'Chinese (Taiwanese Mandarin)' },
  { value: 'hr-HR', text: 'Croatian (Croatia)' },
  { value: 'cs-CZ', text: 'Czech (Czech)' },
  { value: 'da-DK', text: 'Danish (Denmark)' },
  { value: 'nl-NL', text: 'Dutch (Netherlands)' },
  { value: 'en-AU', text: 'English (Australia)' },
  { value: 'en-CA', text: 'English (Canada)' },
  { value: 'en-GH', text: 'English (Ghana)' },
  { value: 'en-HK', text: 'English (Hong Kong)' },
  { value: 'en-IN', text: 'English (India)' },
  { value: 'en-IE', text: 'English (Ireland)' },
  { value: 'en-KE', text: 'English (Kenya)' },
  { value: 'en-NZ', text: 'English (New Zealand)' },
  { value: 'en-NG', text: 'English (Nigeria)' },
  { value: 'en-PH', text: 'English (Philippines)' },
  { value: 'en-SG', text: 'English (Singapore)' },
  { value: 'en-ZA', text: 'English (South Africa)' },
  { value: 'en-TZ', text: 'English (Tanzania)' },
  { value: 'en-GB', text: 'English (United Kingdom)' },
  { value: 'en-US', text: 'English (United States)' },
  { value: 'et-EE', text: 'Estonian(Estonia)' },
  { value: 'fil-PH', text: 'Filipino (Philippines)' },
  { value: 'fi-FI', text: 'Finnish (Finland)' },
  { value: 'fr-CA', text: 'French (Canada)' },
  { value: 'fr-FR', text: 'French (France)' },
  { value: 'fr-CH', text: 'French (Switzerland)' },
  { value: 'de-AT', text: 'German (Austria)' },
  { value: 'de-CH', text: 'German (Switzerland)' },
  { value: 'de-DE', text: 'German (Germany)' },
  { value: 'el-GR', text: 'Greek (Greece)' },
  { value: 'gu-IN', text: 'Gujarati (Indian)' },
  { value: 'he-IL', text: 'Hebrew (Israel)' },
  { value: 'hi-IN', text: 'Hindi (India)' },
  { value: 'hu-HU', text: 'Hungarian (Hungary)' },
  { value: 'id-ID', text: 'Indonesian (Indonesia)' },
  { value: 'ga-IE', text: 'Irish (Ireland)' },
  { value: 'it-IT', text: 'Italian (Italy)' },
  { value: 'ja-JP', text: 'Japanese (Japan)' },
  { value: 'kn-IN', text: 'Kannada (India)' },
  { value: 'ko-KR', text: 'Korean (Korea)' },
  { value: 'lv-LV', text: 'Latvian (Latvia)' },
  { value: 'lt-LT', text: 'Lithuanian (Lithuania)' },
  { value: 'ms-MY', text: 'Malay (Malaysia)' },
  { value: 'mt-MT', text: 'Maltese (Malta)' },
  { value: 'mr-IN', text: 'Marathi (India)' },
  { value: 'nb-NO', text: 'Norwegian (Bokmål, Norway)' },
  { value: 'fa-IR', text: 'Persian (Iran)' },
  { value: 'pl-PL', text: 'Polish (Poland)' },
  { value: 'pt-BR', text: 'Portuguese (Brazil)' },
  { value: 'pt-PT', text: 'Portuguese (Portugal)' },
  { value: 'ro-RO', text: 'Romanian (Romania)' },
  { value: 'ru-RU', text: 'Russian (Russia)' },
  { value: 'sk-SK', text: 'Slovak (Slovakia)' },
  { value: 'sl-SI', text: 'Slovenian (Slovenia)' },
  { value: 'es-AR', text: 'Spanish (Argentina)' },
  { value: 'es-BO', text: 'Spanish (Bolivia)' },
  { value: 'es-CL', text: 'Spanish (Chile)' },
  { value: 'es-CO', text: 'Spanish (Colombia)' },
  { value: 'es-CR', text: 'Spanish (Costa Rica)' },
  { value: 'es-CU', text: 'Spanish (Cuba)' },
  { value: 'es-DO', text: 'Spanish (Dominican Republic)' },
  { value: 'es-EC', text: 'Spanish (Ecuador)' },
  { value: 'es-SV', text: 'Spanish (El Salvador)' },
  { value: 'es-GQ', text: 'Spanish (Equatorial Guinea)' },
  { value: 'es-GT', text: 'Spanish (Guatemala)' },
  { value: 'es-HN', text: 'Spanish (Honduras)' },
  { value: 'es-MX', text: 'Spanish (Mexico)' },
  { value: 'es-NI', text: 'Spanish (Nicaragua)' },
  { value: 'es-PA', text: 'Spanish (Panama)' },
  { value: 'es-PY', text: 'Spanish (Paraguay)' },
  { value: 'es-PE', text: 'Spanish (Peru)' },
  { value: 'es-PR', text: 'Spanish (Puerto Rico)' },
  { value: 'es-ES', text: 'Spanish (Spain)' },
  { value: 'es-UY', text: 'Spanish (Uruguay)' },
  { value: 'es-US', text: 'Spanish (USA)' },
  { value: 'es-VE', text: 'Spanish (Venezuela)' },
  { value: 'sw-KE', text: 'Swahili (Kenya)' },
  { value: 'sv-SE', text: 'Swedish (Sweden)' },
  { value: 'ta-IN', text: 'Tamil (India)' },
  { value: 'te-IN', text: 'Telugu (India)' },
  { value: 'th-TH', text: 'Thai (Thailand)' },
  { value: 'tr-TR', text: 'Turkish (Turkey)' },
  { value: 'vi-VN', text: 'Vietnamese (Vietnam)' },
]);

export const supportedStemmingLanguages = Object.freeze([
  'ar', 'da', 'nl', 'en', 'fi', 'fr', 'de',
  'hu', 'it', 'nb', 'pt', 'ro', 'ru', 'es', 'sv',
]);

// Statistics
export const intervalOptions = Object.freeze({
  // MINUTE: 'Minute',
  HOUR: 'hour_datetime',
  DAY: 'day_datetime',
  MONTH: 'month_datetime',
  FULL: 'Full',
});
export const mappedIntervalOptions = Object.freeze([
  { text: 'Hour', value: 'hour_datetime' },
  { text: 'Day', value: 'day_datetime' },
  { text: 'Month', value: 'month_datetime' },
  { text: 'Full', value: 'Full' },
]);
export const groupByOptions = Object.freeze({
  AGENT: 'agent_id',
  DEPARTMENT: 'department_id',
  TAG: 'tag',
  STATUS: 'status',
  SENTIMENT: 'sentiment_call',
});
export const mappedGroupByOptions = Object.freeze([
  { text: 'Agent', value: 'agent_id' },
  { text: 'Department', value: 'department_id' },
  { text: 'Tag', value: 'tag' },
  { text: 'Status', value: 'status' },
  { text: 'Sentiment', value: 'sentiment_call' },
]);

export const metricOptions = Object.freeze([
  { value: 'call_count', text: 'Conversation count', tooltip: 'Number of conversations' },
  { value: 'average_conversation_score', text: 'Average conversation score', tooltip: 'Average conversation score over time' },
  { value: 'average_transcript_duration', text: 'Average transcript duration', tooltip: 'Average duration of call transcript' },
  { value: 'sum_transcript_duration', text: 'Sum transcript duration', tooltip: 'Sum of the duration of call transcripts' },
  { value: 'average_call_duration', text: 'Average call duration', tooltip: 'Average duration of call' },
  { value: 'average_handling_time', text: 'Average handling time', tooltip: 'The average amount of time the agent spends handling a call' },
  { value: 'words_per_minute_visitor', text: 'Average visitor words per minute', tooltip: 'Average number of words per minute spoken by visitor' },
  { value: 'words_per_minute_agent', text: 'Average agent words per minute', tooltip: 'Average number of words per minute spoken by agent' },
  { value: 'average_time_between_phrases', text: 'Average time between phrases', tooltip: 'Average time between spoken phrases' },
]);

export const sentimentOptions = Object.freeze([
  { value: 'positive', text: 'Positive' },
  { value: 'neutral', text: 'Neutral' },
  { value: 'negative', text: 'Negative' },
  { value: 'null', text: 'Without sentiment' },
]);

function getMonday() {
  const d = new Date();
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}
export const connectorTooltipMap = Object.freeze({
  zendeskdatasource: 'Select which zendesk departments should be included',
  puzzeldatasource: 'Select which puzzel queues should be included',
  triodatasource: 'Select which trio queues should be included',
  threecxdatasource: 'Select which departments should be included',
  fileuploaddatasource: 'Select which departments should be included',
});
export const defaultFilterValues = {
  interval: intervalOptions.DAY,
  startDate: getMonday(),
  endDate: new Date(),
  groupBy: agentAnonymizationMode() !== 'FULL' ? groupByOptions.AGENT : groupByOptions.DEPARTMENT,
  analyzer: null,
  timeRange: 'this_week',
  department: [],
  agent: [],
  tag: [],
  status: ['done'],
  transcribeDuration: [],
  sentiments: sentimentOptions.map((e) => e.value),
  includeCategory: [],
  includeAll: true,
  excludeCategory: [],
  excludeAll: true,
  metrics: [metricOptions[0].value],
  search: '',
  excludeEmptyVisitorTranscript: true,
  excludeEmptyAgentTranscript: false,
};
export const searchOperatorTypes = Object.freeze([
  { value: 'OR', text: 'OR' },
  { value: 'AND', text: 'AND' },
]);
export const userPermission = Object.freeze({
  NORMAL: 'normal',
  SUPERUSER: 'super',
});
