<template>
  <AppTemplate
    v-bind="{
      pages: filteredPages,
      showSidebar,
      userDetails,
      toolDetails: {
        name: 'SpeechAnalytics',
        env: speechAnalyticsEnvironment,
      },
      showSpinner: pageLoading,
      loggedIn,
    }"
    notifications-center
  >
    <template #header>
      <div
        v-if="loggedIn && $route.name !== 'home'"
        class="mr-3 inline-flex"
      >
        <ButtonIcon
          v-tooltip.bottom="'Connectors'"
          class="mr-2 size-9"
          icon="link"
          @click="$router.push({ name: 'connector-overview' })"
        />
        <ButtonIcon
          v-tooltip.bottom="'Analyzers'"
          class="mr-2 size-9"
          icon="microphone-alt"
          @click="$router.push({ name: 'analyzer-overview' })"
        />
        <ButtonIcon
          v-tooltip.bottom="'Administration'"
          class="size-9"
          icon="user-lock"
          @click="$router.push({ name: 'administration' })"
        />
      </div>
      <ButtonIcon
        v-if="loggedIn"
        id="info-button"
        v-tooltip="'Show software version'"
        aria-label="Show software version"
        class="size-9"
        :aria-controls="versionInfoModalShowing ? 'version-info-modal' : null"
        :aria-expanded="versionInfoModalShowing"
        icon="info"
        @click="versionInfoModalShowing = true"
      />

      <PvDialog
        id="version-info-modal"
        v-model:visible="versionInfoModalShowing"
        header="Version Information"
        modal
      >
        <PvDataTable
          size="small"
          :value="versionInfo"
        >
          <PvColumn field="name" header="Name" />
          <PvColumn field="value" header="Value" />
        </PvDataTable>
        <template #footer>
          <PvButton
            autofocus
            label="Close"
            severity="secondary"
            @click="versionInfoModalShowing = false"
          />
        </template>
      </PvDialog>
    </template>

    <template #page-content>
      <RouterView v-slot="{ Component }">
        <FadeInOut mode="out-in">
          <component :is="Component" />
        </FadeInOut>
      </RouterView>
    </template>
  </AppTemplate>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AppTemplate from 'supwiz/components-vue3/template/AppTemplate.vue';
import { getSpeechAnalyticsEnvironment, versionInfoArray } from '@/js/utils';
import { agentAnonymizationMode } from '@/js/featureFlags';
import ButtonIcon from 'supwiz/components-vue3/template/shared/components/ButtonIcon.vue';

export default {
  components: { AppTemplate, ButtonIcon },
  data() {
    return {
      versionInfoModalShowing: false,
    };
  },
  computed: {
    ...mapState('sidebar', ['pageLoading', 'showSidebar']),
    ...mapGetters('sidebar', ['loggedIn', 'pages']),
    ...mapGetters('auth', ['displayName', 'isSuperuser']),
    filteredPages() {
      if (this.$route.fullPath.startsWith('/analyzer')
     && this.$route.params.analyzerId) {
        const pages = [...this.pages];
        if (agentAnonymizationMode() === 'FULL') {
          pages.splice(2, 1);
        }
        return pages;
      }
      return [];
    },
    userDetails() {
      return {
        displayName: this.displayName,
      };
    },
    speechAnalyticsEnvironment() {
      return getSpeechAnalyticsEnvironment();
    },
    versionInfo() { return versionInfoArray(); },
  },
  methods: {
    versionInfoArray,
    isRouteActive(name) {
      return this.$route.path.includes(name);
    },
  },
};
</script>

<style scoped>
</style>

<style>
</style>
