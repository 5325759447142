export function isProduction() {
  return process.env.NODE_ENV === 'production';
}
export function agentAnonymizationMode() {
  return !isProduction() ? process.env.AGENT_ANONYMIZATION_MODE
    : window.agentAnonymizationMode;
}
export function openAiEnabled() {
  const val = !isProduction()
    ? process.env.OPEN_AI_ENABLED
    : window.openAiEnabled;
  return val === 'True';
}
export function showResetPassword() {
  const val = !isProduction() ? process.env.SHOW_RESET_PASSWORD
    : window.showResetPassword;
  return val === 'True';
}
