import axios from 'axios';
import { intervalOptions } from '@/js/constants';
import endpoints from '@/js/endpoints';
import {
  getAgentInsightsMetrics, getConversationInsightsMetrics,
  getAnalyticsMetrics, getConversationInsightsDistributionMetrics,
} from '@/js/utils';
import { objToCamel, objToSnake } from 'supwiz/util/data';

function calculatePreviousPeriodFilters(startDate, endDate) {
  if (!startDate || !endDate) {
    throw new Error('Invalid date format in filters.');
  }
  const duration = endDate - startDate;
  const previousStart = new Date(startDate - duration);
  const previousEnd = new Date(endDate - duration);
  return {
    prev_start_date: previousStart,
    prev_end_date: previousEnd,
  };
}
function getRequestConfig(
  analyzerId,
  filters,
  startTime,
  endTime,
  interval,
  distributionInterval,
  type,
) {
  const config = {
    global_filters: {
      analyzer_id: analyzerId,
      start_date: new Date(startTime),
      end_date: new Date(endTime),
      ...calculatePreviousPeriodFilters(startTime, endTime),
      search: filters.search,
      include_category: filters.includeCategory,
      include_all: filters.includeAll,
      exclude_category: filters.excludeCategory,
      exclude_all: filters.excludeAll,
      department_id: filters.department.map((e) => `${e}`),
      agent_id: filters.agentId,
      sentiments: filters.sentiments,
      transcribe_duration: filters.transcribeDuration,
      status: filters.status || ['done'],
    },
    timezone: 'Europe/Copenhagen',
  };
  switch (type) {
    case 'agentInsights':
      config.global_filters.group_by = filters.groupBy;
      config.metrics = getAgentInsightsMetrics();
      break;
    case 'conversationInsights':
      config.global_filters.group_by = 'analyzer';
      config.metrics = getConversationInsightsMetrics(distributionInterval);
      break;
    case 'analytics':
      config.global_filters.group_by = filters.groupBy;
      config.global_filters.transcribe_duration = filters.transcribeDuration;
      config.metrics = getAnalyticsMetrics(filters);
      break;
    case 'insightsDistribution':
      config.global_filters.group_by = 'analyzer';
      config.metrics = getConversationInsightsDistributionMetrics(distributionInterval);
      break;
    default:
      break;
  }

  if (filters.tag.length > 0) {
    config.global_filters.tag = filters.tag;
  }
  return config;
}
const statisticsState = {
  statisticsData: {
    agentInsights: {},
    conversationInsights: {},
    analytics: {},
  },
  // below object is used for storing filters, when switching between pages
  localStatisticsFilters: {
    agentInsights: null,
    conversationInsights: null,
    analytics: null,
  },
  computingStats: false,
  computingDistribution: false,
  selectedDistributionInterval: intervalOptions.DAY,
  bestConversationPerformanceItems: [],
  worstConversationPerformanceItems: [],
  isFetchingConversations: false,
};
const statisticsGetters = {
  getStatistics: (state) => (key) => state.statisticsData[key],
  getSavedFilters: (state) => (key) => state.localStatisticsFilters[key],
};
const statisticsMutations = {
  clearLocalStatisticsFilters(state) {
    state.localStatisticsFilters = {
      agentInsights: null,
      conversationInsights: null,
      analytics: null,
    };
  },
  setLocalStatisticsFilters(state, payload) {
    state.localStatisticsFilters[payload.key] = payload.value;
  },
  setSelectedDistributionInterval(state, payload) {
    state.selectedDistributionInterval = payload;
  },
  setStatisticsData(state, payload) {
    state.statisticsData[payload.type] = payload.value;
  },
  resetStatisticsData(state) {
    state.statisticsData = {
      agentInsights: {},
      conversationInsights: {},
      analytics: {},
    };
  },
  setConversationInsightsDistribution(state, payload) {
    state.statisticsData.conversationInsights.group_by_interval = payload;
  },
  setIsComputing(state, { key, value }) {
    state[key] = value;
  },
  setWorstConversationPerformanceItems(state, payload) {
    state.worstConversationPerformanceItems = payload.map((e) => objToCamel(e));
  },
  setBestConversationPerformanceItems(state, payload) {
    state.bestConversationPerformanceItems = payload.map((e) => objToCamel(e));
  },
  setIsFetchingConversations(state, payload) {
    state.isFetchingConversations = payload;
  },
};
const statisticsActions = {
  async fetchStatistics({
    dispatch, commit, rootGetters, state,
  }, type) {
    commit('setIsComputing', { key: 'computingStats', value: true });
    const auth = rootGetters['auth/headerAuthorization'];
    const filters = rootGetters['statisticsFiltersStore/filters'];
    const formattedStartDate = new Date(filters.startDate).setHours(0, 0, 0, 0);
    const formattedEndDate = new Date(filters.endDate).setHours(23, 59, 59, 999);
    const interval = filters.interval;
    const config = getRequestConfig(
      rootGetters['analyzer/detailsId'],
      filters,
      formattedStartDate,
      formattedEndDate,
      interval,
      state.selectedDistributionInterval,
      type,
    );
    if (filters.sentiments) {
      config.global_filters.sentiments = filters.sentiments;
    }
    if (config.global_filters.group_by === 'status') {
      config.global_filters.exclude_empty_visitor_transcript = false;
    }
    try {
      const resp = await axios.post(endpoints.statistics, config, auth);

      commit('task/addTask', {
        celeryId: resp.data.celery_id,
        callbackDone: (data) => {
          commit('setStatisticsData', { type, value: data.result });
          commit('setIsComputing', { key: 'computingStats', value: false });
        },
        callbackFailed: (error) => {
          dispatch('sidebar/showWarning', {
            title: 'Failed to fetch analytics',
            text: error,
            severity: 'error',
          }, { root: true });
          commit('setIsComputing', { key: 'computingStats', value: false });
        },
      }, { root: true });
    } catch (error) {
      if (error.response?.data?.interval_endpoints) {
        dispatch('sidebar/showWarning', {
          title: 'Failed to fetch analytics',
          text: 'Too many results found. Try limiting the range or changing the interval.',
        }, { root: true });
      } else {
        dispatch('sidebar/showWarning', {
          title: 'Failed to fetch analytics',
          text: error.message,
        }, { root: true });
      }
      commit('setIsComputing', { key: 'computingStats', value: false });
    }
  },
  async fetchConversationInsightsDistribution({ state, commit, rootGetters }) {
    commit('setIsComputing', { key: 'computingDistribution', value: true });
    const auth = rootGetters['auth/headerAuthorization'];
    const filters = rootGetters['statisticsFiltersStore/filters'];
    const formattedStartDate = new Date(filters.startDate).setHours(0, 0, 0, 0);
    const formattedEndDate = new Date(filters.endDate).setHours(23, 59, 59, 999);
    const config = getRequestConfig(
      rootGetters['analyzer/detailsId'],
      filters,
      formattedStartDate,
      formattedEndDate,
      filters.interval,
      state.selectedDistributionInterval,
      'insightsDistribution',
    );
    try {
      const resp = await axios.post(endpoints.statistics, config, auth);
      if (resp.data.celery_id) {
        commit('task/addTask', {
          celeryId: resp.data.celery_id,
          callbackDone: (data) => {
            commit('setConversationInsightsDistribution', data.result.group_by_interval);
            commit('setIsComputing', { key: 'computingDistribution', value: false });
          },
          callbackFailed: (error) => {
            commit('setIsComputing', { key: 'computingDistribution', value: false });
            throw error;
          },
        }, { root: true });
      }
    } catch (error) {
      commit('setIsComputing', { key: 'computingDistribution', value: false });
      throw error;
    }
  },
  async fetchConversationPerformanceItems({ dispatch, commit, rootGetters }) {
    commit('setIsFetchingConversations', true);
    const filters = rootGetters['statisticsFiltersStore/filters'];
    filters.hasConversationScore = true;
    filters.analyzer_id = [rootGetters['analyzer/detailsId']];
    filters.status = filters.status || ['done'];
    const params = {
      start_time: filters.startDate,
      end_time: filters.endDate,
      sort_by: '-conversation_score',
      analytics_filter: objToSnake(filters),
    };
    const queryParams = {
      page: 1,
    };
    const topCalls = await dispatch('call/fetchCalls', { params, queryParams }, { root: true });
    params.sort_by = 'conversation_score';
    const worstCalls = await dispatch('call/fetchCalls', { params, queryParams }, { root: true });
    commit('setBestConversationPerformanceItems', topCalls || []);
    commit('setWorstConversationPerformanceItems', worstCalls || []);
  },
};

export default {
  namespaced: true,
  state: statisticsState,
  getters: statisticsGetters,
  mutations: statisticsMutations,
  actions: statisticsActions,
};
