import endpoints from '@/js/endpoints';
import { getModuleProps } from '@/js/utils';
import GeneralTemplate from '@/js/vuexTemplates/general';

const categoriesState = {};

const categoriesGetters = {
  analyzerCategories: (state, getters, rootState) => Object.values(state.items).filter((e) => `${e.analyzer}` === `${rootState.analyzer.details.id}`),
};
const mutations = {};

const actions = {};

const templates = [
  new GeneralTemplate(endpoints.filterCategory, 'category'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: categoriesState, getters: categoriesGetters, mutations, actions,
  }, templates),
};
