import endpoints from '@/js/endpoints';
import { getModuleProps } from '@/js/utils';
import GeneralTemplate from '@/js/vuexTemplates/general';

const analyzerState = {};

const analyzerGetters = {};

const mutations = {};

const actions = {};

const templates = [
  new GeneralTemplate(endpoints.analyzer, 'analyzer'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: analyzerState, getters: analyzerGetters, mutations, actions,
  }, templates),
};
